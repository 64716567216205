import React, { lazy, useEffect, useState } from 'react';
import { Container } from '@ui';
import { TypeWriter } from './typewriter';
import { Button } from '@ui';
import { Box, IsDesktop, pxRem } from '@sanch941/lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PhoneWithCardsBg } from './phone-with-cards-bg';
import { useAppDispatch } from '@store';
import { requestFormActions } from '@features/request-form/model';
import { innerHeight } from '@lib/inner-dimensions';

import sprite from '@assets/images/home/sprite.png';
import styled from 'styled-components';

const RequestForm = lazy(() => import('@features/request-form'));

export const WelcomeTemplate = () => {
    const { t } = useTranslation();
    const [showBtn, setShowBtn] = useState(false);
    const isSSR = typeof window === 'undefined';

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > innerHeight / 2) {
                setShowBtn(true);
            } else {
                setShowBtn(false);
            }
        });
    }, []);

    const dispatch = useAppDispatch();

    const openModal = () => {
        dispatch(requestFormActions.setOpen(true));
    };

    return (
        <Box $bottom={91} sm={{ $bottom: 450 }}>
            <PhoneWithCardsBg />

            <Container>
                <Box $top={59} sm={{ $top: 0 }} pTop={100}>
                    <TypeWriter />
                </Box>
            </Container>

            <Container>
                <Box $top={25} $bottom={30}>
                    <StyledIconsSprite src={sprite} alt="" />
                </Box>

                <Box sm={{ $flex: true, ai: 'center' }}>
                    <Button onClick={openModal}>
                        {t('home.hero.integrate_button.name')}
                    </Button>
                </Box>
            </Container>

            {!isSSR && (
                <React.Suspense fallback={null}>
                    <RequestForm />
                </React.Suspense>
            )}

            <IsDesktop>
                <StyledButton showBtn={showBtn}>
                    <Button onClick={openModal} variant="gradient">
                        {t('home.hero.integrate_button.name')}
                    </Button>
                </StyledButton>
            </IsDesktop>
        </Box>
    );
};

const StyledIconsSprite = styled.img`
    width: ${pxRem(125)};
`;

const StyledButton = styled.div<{ showBtn: boolean }>`
    position: fixed;
    transition: right 0.8s ease-in-out;
    bottom: ${pxRem(50)};
    right: ${({ showBtn }) => (showBtn ? `${pxRem(50)}` : `-100%`)};
    z-index: 20;
`;
