import { isDesktop, pxRem, RichText } from '@lib';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import {
    Box,
    media,
    Text,
    MappedStrapiListItem,
    StrapiImage
} from '@sanch941/lib';
import { Scene } from 'react-scrollmagic';

export const Card: FC<ComponentProps> = ({
    name,
    description,
    img,
    rich_name,
    rich_description
}) => {
    return (
        <Scene
            enabled={!isDesktop}
            indicators={_DEV_}
            offset={100}
            duration={140}
        >
            {(progress) => {
                return (
                    <StyledContainer active={progress < 1 && progress > 0}>
                        <StyledInner>
                            <StyledFront>
                                <Box
                                    $height="100%"
                                    $width="100%"
                                    $flex
                                    ai="center"
                                >
                                    <StyledImg
                                        widthOnScreen={{
                                            common: 137,
                                            mobile: 124
                                        }}
                                        {...img}
                                    />
                                    <StyledText
                                        $color="#450ABE"
                                        fz={16}
                                        lh={19}
                                        fw={700}
                                        ta="left"
                                        sm={{ fz: 24, lh: 28 }}
                                        text={rich_name}
                                    />
                                </Box>
                            </StyledFront>

                            <StyledBack>
                                <RichText
                                    ta="center"
                                    fz={14}
                                    sm={{ fz: 18, lh: 21 }}
                                    $color="#626262"
                                    text={rich_description}
                                />
                            </StyledBack>
                        </StyledInner>
                    </StyledContainer>
                );
            }}
        </Scene>
    );
};

interface ComponentProps extends MappedStrapiListItem {}

const StyledInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    border-radius: 18px;
    background-color: white;
`;

const StyledContainer = styled.div<{ active: boolean }>`
    height: ${pxRem(140)};
    width: 100%;
    perspective: 1000px;

    &:not(:first-child) {
        margin-top: ${pxRem(15)};
    }

    ${({ active }) =>
        active &&
        css`
            ${StyledInner} {
                transform: rotateY(180deg);
            }
        `}

    ${media.md} {
        height: ${pxRem(200)};
        border-radius: 20px;
        width: 32.5%;

        &:not(:first-child) {
            margin-top: ${pxRem(18)};
        }
        margin-top: ${pxRem(18)};

        &:hover ${StyledInner} {
            transform: rotateY(180deg);
        }
    }
`;

const StyledFront = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
`;

const StyledBack = styled(StyledFront)`
    transform: rotateY(180deg) translateZ(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${pxRem(13)};
`;

const StyledImg = styled(StrapiImage)`
    height: 100%;

    ${media.md} {
        height: ${pxRem(149)};
    }
`;

const StyledText = styled(RichText)`
    display: inline-flex;
    flex: 1;
    margin: 0 ${pxRem(15)};

    ${media.md} {
        margin: 0 ${pxRem(10)};
    }
`;
