import { Box } from '@sanch941/lib';
import React from 'react';
import { Slider } from './slider';

export const PartnersTemplate = () => {
    return (
        <Box $top={40} sm={{ $top: 200 }}>
            <Box $top={30}>
                <Slider />
            </Box>
        </Box>
    );
};
