import React from 'react';
import { colors, pxRem } from '@lib';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    Box,
    makeTriangle,
    MappedStrapiListItem,
    media,
    Text
} from '@sanch941/lib';
import { Container } from '@ui';
import { Card } from './card';
import { innerWidth } from '@lib/inner-dimensions';
import styled, { css } from 'styled-components';

export const IntegrationTemplate = () => {
    const { t } = useTranslation();
    const cards: MappedStrapiListItem[] = t('home.integration.cards');

    return (
        <StyledContainer>
            <Container>
                <StyledTitle
                    $color={colors.mainPurple}
                    fw={700}
                    fz={32}
                    lh={38}
                    ta="center"
                >
                    {t('home.integration.title')}
                </StyledTitle>

                <StyledCards
                    $top={50}
                    sm={{
                        $top: 73,
                        $flex: true,
                        $left: 30,
                        $right: 30,
                        ai: 'center',
                        jc: 'center'
                    }}
                >
                    {cards.map(({ name, img, rich_name }, idx) => (
                        <Card text={rich_name} idx={idx + 1} {...img} key={idx}>
                            {name}
                        </Card>
                    ))}
                </StyledCards>
            </Container>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    background-color: #fff;
    padding: ${pxRem(45)} 0 ${pxRem(76)};
    position: relative;

    ${makeTriangle(css`
        border-width: 0 0 ${pxRem(25)} ${innerWidth}px;
        border-color: transparent transparent white transparent;
    `)};

    ${media.md} {
        padding-bottom: ${pxRem(100)};

        &::before {
            border-width: 0 0 ${pxRem(119)} ${innerWidth}px;
        }
    }
`;

const StyledTitle = styled(Text)`
    padding: 0 ${pxRem(60)};
`;

const StyledCards = styled(Box)`
    filter: drop-shadow(0px 13.5309px 36.0825px rgba(51, 23, 94, 0.2));
`;
