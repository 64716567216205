import React, { useMemo } from 'react';
import { convertIntWithSpaces, pxRem } from '@lib';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@sanch941/lib';
import { Range } from './range';
import { useAppSelector } from '@store';
import { incomes } from './model';
import styled from 'styled-components';

export const Card = () => {
    const { t } = useTranslation();
    const multiplier = useAppSelector(
        ({ homeCalculator }) => homeCalculator.multiplier
    );

    const { tarlanIncome, bankIncome, income } = useMemo(() => {
        const income = incomes[multiplier];
        const tarlanIncome = Math.round(income * 0.995);
        const bankIncome = Math.round(income * 0.97);

        return {
            tarlanIncome: convertIntWithSpaces(tarlanIncome),
            bankIncome: convertIntWithSpaces(bankIncome),
            income: convertIntWithSpaces(income)
        };
    }, [multiplier]);

    return (
        <StyledContainer>
            <Box $flex jc="space-between">
                <Text {...fzlh1416} sm={{ fz: 20 }}>
                    {t('monthly_turnover')}:
                </Text>
                <Text {...fzlh1416} sm={{ fz: 20 }} fw={500} $color="#470BBE">
                    от {income}₸
                </Text>
            </Box>

            <Box $top={20} $bottom={20}>
                <Range />
            </Box>

            <Box>
                <Box $flex jc="space-between" ai="flex-end">
                    <Box $width="50%" md={{ $width: 'auto' }}>
                        <Text {...fzlh1416}>
                            {t('monthly_turnover_with_tarlan')}
                        </Text>
                        <Box $top={10}>
                            <Text
                                fz={24}
                                fw={700}
                                $color="#450ABE"
                                sm={{ fz: 36 }}
                            >
                                {tarlanIncome} ₸
                            </Text>
                        </Box>
                    </Box>

                    <Box $width="50%" md={{ $width: 'auto' }}>
                        <Text {...fzlh1416} ta="right">
                            {t('monthly_turnover_without_tarlan')}
                        </Text>
                        <Box $top={10}>
                            <Text fz={24} fw={700} md={{ fz: 36 }} ta="right">
                                {bankIncome} ₸
                            </Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </StyledContainer>
    );
};

const fzlh1416 = {
    fz: 14,
    lh: 16
};

const StyledContainer = styled.div`
    padding: ${pxRem(30)};
    box-shadow: 0px 15px 40px rgba(51, 23, 94, 0.2);
    border-radius: 20px;
`;
