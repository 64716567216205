import React from 'react';
import Typewriter from 'typewriter-effect';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { RichText } from '@lib';
import { LightListItem } from '@lib/types';
import { Text, Box, pxRem } from '@sanch941/lib';
import styled from 'styled-components';

export const TypeWriter = () => {
    const { t } = useTranslation();
    const typewriterList: LightListItem[] = t('home.hero.typewriter_new');

    return (
        <>
            <StyledBox>
                <RichText as="h1" text={t('home.hero.title')} {...textProps} />
            </StyledBox>

            <Text as="h2" $color="#450abe" {...textProps}>
                <Typewriter
                    options={{
                        loop: true,
                        delay: 75,
                        cursor: '<div class="typewritter__cursor" />'
                    }}
                    onInit={(typewriter) => {
                        typewriter
                            .pauseFor(300)
                            .typeString(typewriterList[0]?.name)
                            .pauseFor(2000)
                            .deleteAll()
                            .typeString(typewriterList[1]?.name)
                            .pauseFor(2000)
                            .deleteAll()
                            .typeString(typewriterList[2]?.name)
                            .pauseFor(2000)
                            .start();
                    }}
                />
            </Text>
        </>
    );
};

const StyledBox = styled(Box)`
    max-width: ${pxRem(400)};
`;

const textProps = {
    fz: 32,
    lh: 38,
    fw: 700,
    md: {
        fz: 50,
        lh: 61
    }
};
