import React, { useEffect } from 'react';
import { Box, media, pxRem, IsMobile, IsDesktop } from '@sanch941/lib';
import styled from 'styled-components';

import desktop from '@assets/images/home/home_welcome_desktop.webp';
import mobile from '@assets/images/home/home_welcome_mobile.webp';

export const PhoneWithCardsBg = () => {
    useEffect(() => {
        const imageList = [desktop, mobile];
        imageList.forEach((image) => (new Image().src = image));
    }, []);

    return (
        <StyledContainer>
            <StyledVideoContainer
                $height={274}
                $width="100%"
                sm={{ $height: 493, $width: 877 }}
            >
                <IsMobile>
                    <StyledPaymentForm src={mobile} alt="Phone" />
                </IsMobile>
                <IsDesktop>
                    <StyledPaymentForm src={desktop} alt="Phone" />
                </IsDesktop>
            </StyledVideoContainer>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    position: relative;
    left: ${pxRem(10)};
    width: 100%;

    ${media.md} {
        left: 0;
        right: 0;
        top: ${pxRem(-10)};
        margin: auto;
        position: absolute;
        width: ${pxRem(1200)};
    }
`;

const StyledVideoContainer = styled(Box)`
    z-index: 2;

    ${media.md} {
        position: absolute;
        top: -${pxRem(40)};
        left: 40%;
    }
`;

const StyledPaymentForm = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 100%;

    ${media.md} {
        left: -${pxRem(49)};
        top: 0;
        right: auto;
    }
`;
