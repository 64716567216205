import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { RichText } from '@lib';

export const Description = () => {
    const { t } = useTranslation();

    return (
        <RichText
            fz={16}
            lh={19}
            sm={{ fz: 24, lh: 28 }}
            text={t('home.calculator.rich_description')}
        />
    );
};
