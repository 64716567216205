import React from 'react';
import { Box, Text } from '@sanch941/lib';
import { useTranslation } from 'react-i18next';
import { RichText } from '@lib';
import styled from 'styled-components';

export const MarketingText = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box sm={{ $flex: true, ai: 'center' }}>
                <RichText
                    fw={900}
                    $color="#470BBE"
                    fz={60.9}
                    sm={{ fz: 115.4 }}
                    text={t('home.calculator.rich_percent')}
                />

                <Box sm={{ $left: 15 }}>
                    <StyledText
                        fw={900}
                        fz={29.5}
                        lh={35}
                        sm={{ fz: 44.1, lh: 52 }}
                    >
                        {t('success')} <br /> {t('payments')}
                    </StyledText>
                </Box>
            </Box>
        </>
    );
};

const StyledText = styled(Text)`
    text-transform: uppercase;
`;
