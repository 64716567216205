import { media, pxRem } from '@sanch941/lib';
import styled from 'styled-components';

export const Num = styled.div`
    color: white;
    border-radius: 100%;
    background-color: #4b0bb8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: ${pxRem(40)};
    width: ${pxRem(40)};
    font-size: ${pxRem(23)};

    ${media.md} {
        height: ${pxRem(55)};
        width: ${pxRem(55)};
        font-size: ${pxRem(30)};
    }
`;
