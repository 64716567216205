import { pxRem, RichText } from '@lib';
import React, { FC } from 'react';
import styled from 'styled-components';
import {
    media,
    MappedStrapiImage,
    StrapiImage,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import playIcon from '@assets/images/integration/play.svg';
import { Num } from './num';

export const Card: FC<ComponentProps> = ({
    text,
    idx,
    children,
    ...strapiImgProps
}) => {
    return (
        <StyledContainer id={`integration__num${idx}`}>
            <StyledImg
                widthOnScreen={{ common: 300, mobile: 170 }}
                {...strapiImgProps}
            />
            <StyledText
                fw={700}
                fz={14}
                lh={16}
                $color="#450abe"
                md={{
                    fz: 20,
                    lh: 23
                }}
                text={text}
            />

            <StyledNumContainer>
                <Num> {idx} </Num>
            </StyledNumContainer>
        </StyledContainer>
    );
};

interface ComponentProps extends MappedStrapiImage {
    idx: number;
    text: StrapiResponsiveRichTextEditorFromRes;
}

const StyledImg = styled(StrapiImage)`
    height: ${pxRem(170)};
    width: 100%;

    ${media.md} {
        height: ${pxRem(180)};
        width: 100%;
    }
`;

const StyledText = styled(RichText)`
    ${media.md} {
        padding: 0 ${pxRem(24)};
    }
`;

const StyledNumContainer = styled.div`
    position: absolute;
    z-index: 9;
    opacity: 1;
    top: -${pxRem(10)};
    bottom: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-${pxRem(17)});
`;

const StyledContainer = styled.div`
    height: ${pxRem(230)};
    width: ${pxRem(170)};
    background-color: white;
    text-align: center;
    position: relative;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    z-index: 10;
    margin: 0 auto;

    &:not(:first-child) {
        margin-top: ${pxRem(80)};
    }

    &:not(:last-child) {
        &::before {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
            top: 103%;
            margin: auto;
            width: ${pxRem(34)};
            height: ${pxRem(21)};
            background: url(${playIcon});
            background-size: cover;
            background-position: center center;
        }
    }

    ${media.md} {
        height: ${pxRem(300)};
        width: ${pxRem(200)};
        border-radius: 20px;
        margin: 0;

        &:not(:first-child) {
            margin-top: 0;
            margin-left: ${pxRem(135)};
        }

        &:not(:last-child) {
            &::before {
                content: '';
                position: absolute;
                left: 100%;
                transform: translateX(100%);
                top: 0;
                bottom: 0;
                margin: auto;
                width: ${pxRem(34)};
                height: ${pxRem(21)};
                background: url(${playIcon});
                background-size: cover;
                background-position: center center;
            }
        }
    }
`;
