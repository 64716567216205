import React from 'react';
import { Box, pxRem } from '@sanch941/lib';
import { Card } from './card';
import { MarketingText } from './marketing-text';
import { Description } from './description';
import mobile from '@assets/images/home/calculator_phone_mobile.webp';
import styled from 'styled-components';

export const Mobile = () => {
    return (
        <>
            <Box>
                <StyledPhone src={mobile} alt="Phone" />

                <StyledText>
                    <MarketingText />
                </StyledText>
            </Box>

            <Box $top={30} $bottom={30}>
                <Description />
            </Box>

            <Card />
        </>
    );
};

const StyledText = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-content: flex-end;
    bottom: ${pxRem(15)};
    right: 0;
    font-weight: 900;
    text-align: center;
`;

const StyledPhone = styled.img`
    max-width: ${pxRem(170)};
`;
