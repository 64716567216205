import React from 'react';
import { AdvantagesTemplate } from './templates/advantages/advantages';
import { CalculatorTemplate } from './templates/calculator/calculator';
import { IntegrationTemplate } from './templates/integration/integration';
import { PartnersTemplate } from './templates/partners/partners';
import { WelcomeTemplate } from './templates/welcome/welcome';
import { CommonTemplate } from '@ui';
import { Box } from '@sanch941/lib';

export const HomePage = () => {
    return (
        <CommonTemplate>
            <Box $top={58}>
                <WelcomeTemplate />
                <AdvantagesTemplate />
                <IntegrationTemplate />
                <CalculatorTemplate />
                <PartnersTemplate />
            </Box>
        </CommonTemplate>
    );
};
