import { Container } from '@ui';
import React from 'react';
import styled from 'styled-components';
import { pxRem } from '@lib';
import { IsDesktop, IsMobile, media } from '@sanch941/lib';
import { Mobile } from './mobile';
import { Desktop } from './desktop';
import { innerWidth } from '@lib/inner-dimensions';

export const CalculatorTemplate = () => {
    return (
        <StyledContainer>
            <Container>
                <IsMobile>
                    <Mobile />
                </IsMobile>

                <IsDesktop>
                    <Desktop />
                </IsDesktop>
            </Container>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    position: relative;
    padding-top: ${pxRem(60)};

    ${media.md} {
        &::before {
            border-width: 0 0 ${pxRem(119)} ${innerWidth}px;
        }
    }
`;
