import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from 'swiper';
import { MappedStrapiListItem, pxRem, StrapiImage } from '@sanch941/lib';
import styled from 'styled-components';

export const Slider = () => {
    const { t } = useTranslation();
    const cards: MappedStrapiListItem[] = t('home.partners.cards');

    return (
        <StyledContainer>
            <Swiper
                modules={[FreeMode, Autoplay]}
                spaceBetween={30}
                slidesPerView={3}
                longSwipes={false}
                loop={true}
                autoplay={{
                    delay: 0,
                    waitForTransition: true
                }}
                speed={1300}
                breakpoints={{
                    1022: {
                        slidesPerView: 4,
                        speed: 2000
                    }
                }}
            >
                {cards.map((card, idx) => (
                    <SwiperSlide key={idx}>
                        <StrapiImage
                            widthOnScreen={{ common: 161 }}
                            {...card.img}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    height: ${pxRem(122)};
    width: 100%;
    background-color: #efefef;
    display: flex;
    align-items: center;
`;
