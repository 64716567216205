import React from 'react';
import { Box, pxRem } from '@sanch941/lib';
import { Card } from './card';
import { Description } from './description';
import { MarketingText } from './marketing-text';
import desktop from '@assets/images/home/calculator_phone_common.webp';
import styled from 'styled-components';

export const Desktop = () => {
    return (
        <Box $flex jc="space-between">
            <StyledFirstPart>
                <StyledPhone src={desktop} alt="Phone" />
            </StyledFirstPart>

            <StyledSecondPart>
                <MarketingText />

                <Box $top={25} $bottom={13}>
                    <Description />
                </Box>
                <Card />
            </StyledSecondPart>
        </Box>
    );
};

const StyledFirstPart = styled.div`
    flex: 1;
    position: relative;
`;

const StyledSecondPart = styled.div`
    width: ${pxRem(591)};
`;

const StyledPhone = styled.img`
    position: absolute;
    top: 0;
    left: -${pxRem(20)};
    width: 100%;
`;
