import React from 'react';
import { useAppDispatch, useAppSelector } from '@store';
import { homeCalculatorActions } from './model';
import { getTrackBackground, Range as ReactRange } from 'react-range';
import { colors } from '@lib';
import styled from 'styled-components';
import { pxRem } from '@sanch941/lib';

export const Range = () => {
    const multiplier = useAppSelector(
        ({ homeCalculator }) => homeCalculator.multiplier
    );
    const dispatch = useAppDispatch();

    return (
        <ReactRange
            step={1}
            min={min}
            max={max}
            values={[multiplier]}
            onChange={(values) =>
                dispatch(homeCalculatorActions.setMultiplier(Number(values[0])))
            }
            renderTrack={({ props, children }) => (
                <StyledLine
                    {...props}
                    style={{
                        ...props.style,
                        background: getTrackBackground({
                            values: [multiplier],
                            colors: [colors.mainPurple, '#ccc'],
                            min,
                            max
                        })
                    }}
                >
                    {children}
                </StyledLine>
            )}
            renderThumb={({ props }) => (
                <StyledCircle
                    {...props}
                    style={{
                        ...props.style
                    }}
                />
            )}
        />
    );
};

const min = 0;
const max = 4;

const StyledLine = styled.div`
    height: ${pxRem(4)};
    width: 100%;
`;

const StyledCircle = styled.div`
    height: ${pxRem(21)};
    width: ${pxRem(21)};
    border-radius: 100%;
    background-color: ${colors.mainPurple};
    position: relative;
    outline: none;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 65%;
        width: 65%;

        border-radius: 100%;
        background-color: white;
    }
`;
