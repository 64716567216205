import React from 'react';
import { Container } from '@ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Card } from './card';
import {
    Box,
    makeTriangle,
    MappedStrapiListItem,
    media,
    pxRem
} from '@sanch941/lib';
import { Controller } from 'react-scrollmagic';
import { innerWidth } from '@lib/inner-dimensions';
import styled, { css } from 'styled-components';

export const AdvantagesTemplate = () => {
    const { t } = useTranslation();

    const cards: MappedStrapiListItem[] = t('home.advantages.cards');

    return (
        <StyledContainer>
            <Container>
                <Controller>
                    <StyledCards
                        $top={30}
                        sm={{
                            $top: 52,
                            $flex: true,
                            fxw: 'wrap',
                            jc: 'space-between'
                        }}
                    >
                        {cards.map((card, idx) => (
                            <Card key={idx} {...card} />
                        ))}
                    </StyledCards>
                </Controller>
            </Container>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    position: relative;
    padding-bottom: ${pxRem(76)};
    padding-top: ${pxRem(10)};
    background-color: #efefef;

    ${makeTriangle(css`
        border-width: 0 0 ${pxRem(25)} ${innerWidth}px;
        border-color: transparent transparent #efefef transparent;
    `)};

    ${media.md} {
        padding-bottom: ${pxRem(200)};

        &::before {
            border-width: 0 0 ${pxRem(119)} ${innerWidth}px;
        }
    }
`;

export const StyledTriangle = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${pxRem(24)} ${innerWidth}px 0 0;
    border-color: white transparent transparent transparent;
    position: relative;
    top: ${pxRem(74)};
    z-index: 2;

    ${media.md} {
        border-width: ${pxRem(119)} ${innerWidth}px 0 0;
        top: ${pxRem(169)};
    }
`;

const StyledCards = styled(Box)`
    filter: drop-shadow(0px 13.5309px 36.0825px rgba(51, 23, 94, 0.2));
`;
